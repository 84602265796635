import React, { useEffect, useState } from 'react';
import client from '../../logic/courses';
import { IonSpinner } from '@ionic/react';
import Collapsible from 'react-collapsible';
import CollapsibleTrigger from '../UI/CollapsibleTrigger';
import Dishes from './Dishes';
import dl from '../../utils/device-language';
import { useTranslation } from 'react-i18next';
import { useFilters } from '../../utils/contexts/filters-context';
import forms from '../../utils/forms';
import { useTracker } from '../../utils/contexts/tracker-context';
import { useParams } from 'react-router';
import { useRestaurant } from '../../utils/contexts/restaurant-context';
import theme from '../../utils/theme';

function Course(props) {
    const { course, index } = props;

    const { i18n } = useTranslation();
    const { trackDataFn } = useTracker();
    const { type } = useParams();
    const { restaurant } = useRestaurant();

    const [dishes, setDishes] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const { filters } = useFilters();
    const [open, setOpen] = useState(false);

    async function onOpening() {
        // populate dishes with course dishes
        // dishes are loaded from courses logic
        await trackDataFn(restaurant.id, restaurant.token, "courseopen", {
            "type": type,
            "name": this.trigger.props.trigger.name,
            "id": course.id,
        }, restaurant.has_orders);

        if (course.hasOwnProperty('nested')) {
            setLoading(false);
        } else {
            let clientCourse;

            let language = process.env.REACT_APP_LANGUAGESELECTOR === "true" ? i18n.language : dl.getApiLanguage();
            const { allergies, foods, diets } = forms.parseFilters(filters);

            if (allergies || foods || diets) {
                clientCourse = await client.doGetFilters(language, course.id, allergies, foods, diets);
            } else {
                clientCourse = await client.doGet(language, course.id);
            }


            setDishes(clientCourse.dishes);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (open == true) {
            setOpen(prevState => !prevState);
        }
    }, [filters])

    return (
        <Collapsible
            className={`${theme.getCalaveraCustomColor(course.id)} course`}
            openedClassName={`${theme.getCalaveraCustomColor(course.id)} course`}
            tabIndex={index}
            trigger={<CollapsibleTrigger index={index} trigger={course} setOpen={setOpen}></CollapsibleTrigger>}
            triggerWhenOpen={<CollapsibleTrigger index={index} trigger={course} opened setOpen={setOpen}></CollapsibleTrigger>}
            onTriggerOpening={onOpening}
            open={open}>
            <div>
                {process.env.REACT_APP_NAMESPACE === "calavera" && theme.getCalaveraCustomBg(course.id) ?
                    <div className="pb-2">
                        <img className="calavera_course_image object-cover w-full h-[200px]"
                            src={`/assets/calavera/${theme.getCalaveraCustomBg(course.id)}.jpg`}
                            alt="calavera course" />
                    </div> :
                    null}

                {isLoading ?
                    <IonSpinner name="dots" /> :
                    course.hasOwnProperty("nested") ?
                        <div className={`${theme.getCalaveraCustomColor(course.id)} nested`}>
                            {course.nested.map((c, i) =>
                                <div className="m-4 p-0.5 border-2 border-white rounded-lg">
                                    <Course course={c} index={i} key={i} />
                                </div>)}
                        </div> :
                        <Dishes courseName={course.name} courseId={course.id} dishes={dishes} index={index} />}
            </div>
        </Collapsible>
    );
}

export default Course;
