function getThemeIonComponents(theme) {
    if (theme == "cocacola")
        return {
            splashPage: { "--background": "#e6e6e5" },
            menupage: { "--background": "#e6e6e5" },
        };
    if (theme == "morgana") {
        return {
            splashPage: { "--background": "#0C2835" },
            menupage: { "--background": "#0C2835" },
        };
    }
    if (theme == "sushitao") {
        return {
            splashPage: { "--background": "#0C2835" },
            menupage: { "--background": "#0C2835" },
        };
    }
    if (theme == "redcafe") {
        return {
            splashPage: { "--background": "#EEE4D2" },
            menupage: { "--background": "#EEE4D2" },
        };
    }
    if (theme == "motueka") {
        return {
            splashPage: { "--background": "black" },
            menupage: { "--background": "black" },
        };
    }
    if (theme == "camparino") {
        return {
            splashPage: { "--background": "#FFFFFF" },
            menupage: { "--background": "#FFFFFF" },
        };
    }
    if (theme == "voglia_argentina") {
        return {
            splashPage: { "--background": "#FFFFFF" },
            menupage: { "--background": "#FFFFFF" },
        };
    }
    if (theme == "pokeisland") {
        return {
            splashPage: { "--background": "#ECA8AA" },
            menupage: { "--background": "#ECA8AA" },
        };
    }
    if (theme == "ten") {
        return {
            splashPage: { "--background": "#535353" },
            menupage: { "--background": "#535353" },
        };
    }
    if (theme == "montaditos") {
        return {
            splashPage: { "--background": "#F5EFE4" },
            menupage: { "--background": "#FFFFFF" },
        };
    }
    if (theme == "barriobotanico") {
        return {
            splashPage: { "--background": "url(/assets/images/barriobotanico.png)" },
        };
    }
    if (theme == "vanquish") {
        return {
            splashPage: { "--background": "url(/assets/images/vanquish.png)" },
        };
    }
    if (theme == "letorri") {
        return {
            splashPage: { "--background": "black" },
            menupage: { "--background": "black" },
        };
    }
    if (theme == "fellini") {
        return {
            splashPage: { "--background": "url(/assets/images/fellini.png)" },
        };
    }
    if (theme == "agave") {
        return {
            splashPage: { "--background": "url(/assets/images/agave.png) no-repeat center center / cover" },
        };
    }
    if (theme == "villanichesola") {
        return {
            splashPage: { "--background": "url(/assets/images/villa_nichesola.png) no-repeat center" },
        };
    }
    if (theme == "kubic") {
        return {
            splashPage: { "--background": "url(/assets/images/kubic.png) no-repeat center" },
        };
    }
    if (theme == "allright") {
        return {
            splashPage: { "--background": "url(/assets/images/allright.png) no-repeat center center / cover" },
            menupage: { "--background": "url(/assets/images/allright_1.png) no-repeat center center / cover" },
        };
    }
    if (theme == "santo") {
        return {
            splashPage: { "--background": "url(/assets/images/santo.png) no-repeat center center / cover" },
            menupage: { "--background": "url(/assets/images/santo.png) no-repeat center center / cover" },
        };
    }
    if (theme == "redbull") {
        return {
            splashPage: { "--background": "#20447A" },
            menupage: { "--background": "#20447A" },
        };
    }
    if (theme == "sanpellegrino") {
        return {
            splashPage: { "--background": "#002B5C" },
            menupage: { "--background": "#002B5C" },
        };
    }
    if (theme == "atlanta") {
        return {
            splashPage: { "--background": "#1DABE3" },
            menupage: { "--background": "#1DABE3" },
        };
    }
    if (theme == "calavera") {
        return {
            splashPage: { "--background": "#FAF2D5" },
            menupage: { "--background": "#FAF2D5" },
        };
    }
    else return {
        menupage: null
    }
}

function getTheme(id, setterPaletteState) {
    if (id == "9440" || id == "10158") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("motueka");
        setterPaletteState("motueka")
    }
    if (id == "6433") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("camparino");
        setterPaletteState("camparino")
    }
    if (id == "9506") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("voglia_argentina");
        setterPaletteState("voglia_argentina")
    }
    if (id == "9207") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("morgana");
        setterPaletteState("morgana")
    }
    if (id == "9521") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("il-cacciatore");
        setterPaletteState("il-cacciatore");
    }
    if (id == "9520") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("pane-vino");
        setterPaletteState("pane-vino");
    }
    if (id == "9522") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("mela-oro");
        setterPaletteState("mela-oro");
    }
    if (id == "9540") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("hotel-dolomiti");
        setterPaletteState("hotel-dolomiti");
    }
    if (id == "9564") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("mcdonald");
        setterPaletteState("mcdonald");
    }
    if (id == "9570") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("mars");
        setterPaletteState("mars");
    }
    if (id == "9616") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("adr");
        setterPaletteState("adr");
    }
    if (id == "9638") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("pokeisland");
        setterPaletteState("pokeisland");
    }
    if (id == "6474") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("radetzky");
        setterPaletteState("radetzky");
    }
    if (id == 9889 || id == 9888 || id == 9887 || id == 9886 || id == 9885 || id == 9884 || id == 9883 || id == 9882 || id == 9881 || id == 9880 || id == 9879 || id == 10104 || id == 10296 || id == 13091) {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("ten");
        setterPaletteState("ten");
    }
    if (id == "11945") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("montaditos");
        setterPaletteState("montaditos");
    }
    if (id == "9690" || id == "12045") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("barriobotanico");
        setterPaletteState("barriobotanico");
    }
    if (id == "13025") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("capannina");
        setterPaletteState("capannina");
    }
    if (id == "6568") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("vanquish");
        setterPaletteState("vanquish");
    }
    if (id == "13668" || id == "13921") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("letorri");
        setterPaletteState("letorri");
    }
    if (id == "6619") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("fellini");
        setterPaletteState("fellini");
    }
    if (id == "10182") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("agave");
        setterPaletteState("agave");
    }
    if (id == "14164") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("vanvitelli");
        setterPaletteState("vanvitelli");
    }
    if (id == "14711") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("s_pellegrino");
        setterPaletteState("s_pellegrino");
    }
    if (id == "8268") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("giappugliese");
        setterPaletteState("giappugliese");
    }
    if (id == "6757") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("villanichesola");
        setterPaletteState("villanichesola");
    }
    if (id == "6684") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("kubic");
        setterPaletteState("kubic");
    }
    if (id == "8814") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("allright");
        setterPaletteState("allright");
    }
    if (id == "14848") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("santo");
        setterPaletteState("santo");
    }
    if (id == "6870") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("atlanta");
        setterPaletteState("atlanta");
    }
}

function getCustomElement(id, element, t) {
    if (element === "splash") {
        if (id === 9440 || id === 10158) {
            return "Drinklist";
        } else {
            return t("splash.button_1");
        }
    }
    if (element === "newtabs") {
        if (id === 9440 || id === 10158) {
            return "Drinklist";
        } else if (id === 9540) {
            return "BEVANDE";
        } else {
            return t("tab.button_2");
        }
    }
    if (element === "preferreddefaultscreen") {
        if (id === 9440 || id === 10158) {
            return "Premi sull'icona della stella per aggiungere un drink a questa lista. Ritroverai tutti i drink salvati in questa sezione, potendo scegliere tra i tuoi preferiti nel momento della creazione dell'ordine. Premi nuovamente sulla stella per rimuovere un preferito da questa lista.";
        } else {
            return t("preferreddefaultscreen.p_2");
        }
    }
}

function getCalaveraCustomColor(courseName) {
    let calaveraCustomClass;
    switch (courseName) {
        case 73608:
            calaveraCustomClass = "bg-[#A4B93B]";
            break;
        case 73040:
            calaveraCustomClass = "bg-[#C32D28]";
            break;
        case 72287:
            calaveraCustomClass = "bg-[#773E68]";
            break;
        case 72144: case 71933: case 71934: case 71937:
            calaveraCustomClass = "bg-[#F4D936]";
            break;
        case 72236: case 71938: case 71939:
            calaveraCustomClass = "bg-[#F09B32]";
            break;
        case 72237: case 71940: case 72012: case 72013:
            calaveraCustomClass = "bg-[#E25C3D]";
            break;
        case 72238: case 72014: case 72097: case 72098:
            calaveraCustomClass = "bg-[#DD265F]";
            break;
        case 72015:
            calaveraCustomClass = "bg-[#D41E69]";
            break;
        case 72239: case 72033: case 72034: case 72035: case 72036: case 72038: case 72037: case 72032:
            calaveraCustomClass = "bg-[#D92D85]";
            break;
        case 72095:
            calaveraCustomClass = "bg-[#D98A9A]";
            break;
        default:
            calaveraCustomClass = "";
            break;
    }

    return calaveraCustomClass;
}

function getCalaveraCustomBg(id) {
    // const regex = new RegExp(course.name, "gi");
    // course.name.match(regex)?.pop().replaceAll(" ", "_")
    let calaveraCustomBg;
    switch (id) {
        case 73608:
            calaveraCustomBg = "guacamore";
            break;
        case 72287:
            calaveraCustomBg = "el_mes_de_los_muertos";
            break;
        case 72144:
            calaveraCustomBg = "para_empezar";
            break;
        case 72236:
            calaveraCustomBg = "los_classicos";
            break;
        case 72237:
            calaveraCustomBg = "platos_fuertes";
            break;
        case 72238:
            calaveraCustomBg = "menu_ninos";
            break;
        case 72015:
            calaveraCustomBg = "dulces_in_fundo";
            break;
        case 72239:
            calaveraCustomBg = "bebidas";
            break;
        case 72095:
            calaveraCustomBg = "menu_pranzo";
            break;
        default:
            calaveraCustomBg = "";
            break;
    }

    return calaveraCustomBg;
}


export default {
    getThemeIonComponents,
    getTheme,
    getCustomElement,
    getCalaveraCustomColor,
    getCalaveraCustomBg,
}
