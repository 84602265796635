import React, { useEffect, useState } from 'react';
import AllergenIcons from '../UI/AllergenIcons';
import DishModalTrigger from '../modals/DishModalTrigger';
import Ingredients from './Ingredients';
import TranslationModalTrigger from '../modals/TranslationModalTrigger';
import assets from '../../utils/assets';
import dl from '../../utils/device-language';
import formats from '../../utils/formats';
import features from '../../utils/features';
import { useRestaurant } from '../../utils/contexts/restaurant-context';
import parse from "html-react-parser";

function Details(props) {
    const { dish, showDishModal, setShowDishModal, onDetailsOpenHandler } = props;

    const { restaurant } = useRestaurant();

    const [description, setDescription] = useState(dish.description);

    let icons = [];

    (dish.has_sulfites) && icons.push(assets.assetsPath("images/icons/allergie/solfiti.png"));
    (dish.is_frozen) && icons.push(assets.assetsPath("images/icons/allergie/surgelati.png"));
    for (const ingredient of dish.ingredients) {
        ingredient.has_allergens && ingredient.allergens.map((allergen, i) => {
            let path = assets.assetsPath(allergen.image_url);
            !icons.includes(path) && icons.push(path);
            return [];
        });
    }

    useEffect(() => {
        (restaurant.id === 6608 || restaurant.id === 13040) ?
            setDescription(parse(dish.description_raw)) :
            setDescription(formats.formatDescription(dish));
    }, []);

    useEffect(() => {
        description.length > 0 && setDescription(parse(description.replace(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)/g, formats.replacer)));
    }, [description]);

    return (
        <div className="details relative py-1">
            {description ?
                <p className="mb-2 text-sm" style={{ whiteSpace: "pre-wrap" }}>
                    {process.env.REACT_APP_NAMESPACE === "calavera" ? parse(dish.description_raw) : description}
                </p> :
                <Ingredients ingredients={dish.ingredients} />}

            <div className="flex flex-wrap items-center pt-2">
                {process.env.REACT_APP_NAMESPACE === "calavera" ? null : <AllergenIcons icons={icons} />}
                {formats.shouldDisplayDishDetails(dish) ? <DishModalTrigger dish={dish} showDishModal={showDishModal} setShowDishModal={setShowDishModal} onDetailsOpenHandler={onDetailsOpenHandler} /> : null}
            </div>

            {(!(dl.isDeviceLanguage("it") || dl.isDeviceLanguage("it-IT")) && features.toggler().includeSuggestions()) && <TranslationModalTrigger type="dish" dish={dish} />}
        </div>
    );
}

export default Details;
