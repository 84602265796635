import React, { useLayoutEffect } from "react";
import { useParams } from "react-router";
import { IonContent, IonPage } from "@ionic/react";
import { useTracker } from "../utils/contexts/tracker-context";
import { Link } from "react-router-dom";

function CalaveraSplashPage(props) {
    const { trackDataFn } = useTracker();

    const { type } = useParams();

    useLayoutEffect(() => {
        async function track() {
            await trackDataFn("184", "R8OiGM5GW", "usersession", { "type": type }, false);
        }

        track();
    }, []);

    async function trackPageViewHandler() {
        await trackDataFn("184", "R8OiGM5GW", "pageview", { "type": type }, false);
    }

    return (
        <IonPage>
            <IonContent>
                <div className="h-screen bg-cover" style={{ backgroundImage: 'url("/assets/calavera/home.jpg")' }}>
                    <div className="flex justify-center items-center">
                        <div className="flex flex-col items-center p-16 space-y-6">
                            <img src="/assets/calavera/logo.png" alt="calavera logo" />
                            <Link onClick={trackPageViewHandler} to="/r/q/R8OiGM5GW/menu" className="w-full px-3 py-2 text-center font-bold text-white uppercase bg-[#D5076E] rounded-full">vedi menù</Link>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
}

export default CalaveraSplashPage;
