import React from 'react';
import { IonIcon } from '@ionic/react';
import clsx from 'clsx';
import formats from '../../utils/formats';
import { chevronUp, chevronDown } from 'ionicons/icons';
import theme from '../../utils/theme';

function CollapsibleTrigger(props) {
    const { trigger, index, opened, setOpen, wrapCourse } = props;

    let icon = opened ? chevronUp : chevronDown;
    let name = String(trigger.name).toUpperCase();
    let elClassnames = [
        { "wrap-course text-2xl": wrapCourse },
        "collapsible-trigger", "trigger", "cursor-pointer", "flex",
        "justify-between", "py-5", "px-4", "font-semibold", "text-xl",
        { "opened": opened },
        { "sanbitter text-black": process.env.REACT_APP_NAMESPACE === "sanpellegrino" && trigger.custom_theme_sanbitter === true && opened },
        { "sanbitter": process.env.REACT_APP_NAMESPACE === "sanpellegrino" && trigger.custom_theme_sanbitter === true && opened },
        { "pago": process.env.REACT_APP_NAMESPACE === "sanpellegrino" && trigger.custom_theme_pago === true && opened }
    ];
    if (process.env.REACT_APP_NAMESPACE === "calavera") {
        elClassnames.push(theme.getCalaveraCustomColor(trigger.id));
    } else {
        elClassnames.push({ "even bg-white": formats.isEven(index) }, { "odd bg-grey-200": !formats.isEven(index) });
    }

    let iconClassnames = [
        "p-1",
        { "rounded-full shadow-md bg-mycia-red arrow-trigger text-white": opened },
    ];

    return (
        <div className={clsx(elClassnames)} onClick={() => setOpen(prev => !prev)}>
            {name}
            <IonIcon icon={icon} slot="end" className={clsx(iconClassnames)} />
        </div>
    );
}

export default CollapsibleTrigger;
