import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
// import { Document, Page, pdfjs } from 'react-pdf';

function PdfViewer() {
    const pdfURL = "https://drive.google.com/file/d/1TrUd6n221Z-KJScJOUwfVHjHqoSqdKOC/preview";

    // pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    //     'pdfjs-dist/build/pdf.worker.min.mjs',
    //     import.meta.url,
    // ).toString();

    return (
        <IonPage>
            <IonContent>
                {/* <object className="h-screen" data="https://drive.google.com/file/d/1TrUd6n221Z-KJScJOUwfVHjHqoSqdKOC/view?usp=sharing"
                    type='application/pdf'
                    width="100%">
                </object> */}

                {/* <Document file={pdfURL}>
                    <Page />
                </Document> */}

                <iframe title="allergens viewer" src={pdfURL} width="100%" height="100%" />

            </IonContent>
        </IonPage>
    );
}

export default PdfViewer;
