import React from 'react';
import Dish from './Dish';
import formats from '../../utils/formats';
import clsx from 'clsx';
import theme from '../../utils/theme';

function Dishes(props) {
    let { courseName, courseId, dishes, index, isTastingMenu } = props;

    let calaveraCustomClass = theme.getCalaveraCustomColor(courseId);

    let classnames = process.env.REACT_APP_NAMESPACE === "calavera" ?
        ["dishes", calaveraCustomClass] :
        [
            "dishes",
            { "even bg-white": formats.isEven(index) },
            { "odd bg-grey-200": !formats.isEven(index) }
        ];

    return (
        <div className={clsx(classnames)}>
            {0 === dishes.length ?
                (<p>No dishes.</p>) :
                (dishes.map((dish, i) => <Dish dish={dish} key={i} isTastingMenu={isTastingMenu} />))}
        </div>
    );
}

export default Dishes;
